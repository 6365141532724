.Text--bright-blue {
  color: rgb(49, 143, 250);
}

.Text--pointer {
  cursor: pointer;
}

.Text--bold {
  font-weight: bold;
}
