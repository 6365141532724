/* Dimention variables */
$navbar-height: 46px;
$navbar-profile-img-size: 33px;
$sidebar-width: 65px;
$sidebar-width-expanded: 200px;
$main-menu-height: 31px;
$modal-width: 700px;
$modal-confirm-width: 400px;
$modal-form-sm-width: 600px;
$modal-form-width: 900px;
$modal-info-width: 900px;
$modal-header-height: 46px;
$sub-nav-height: 42px;
$padding: 15px;
$padding-horizontal: 0 10px;
$header-height: 50px;
$header-height-sm: 48px;
$main-top: ($navbar-height + $main-menu-height) + 20px;
$card-sidebar-width: 300px;
$card-sidebar-width-sm: 250px;
$card-footer-height: 42px;
$modal-sidebar-width: 235px;
$modal-sidebar-width-wide: 335px;
$modal-sidebar-right-width: 270px;
$up-filters-width: 300px;

// Buttons
$up-btn-height-xs: 22px;
$up-btn-height-sm: 30px;
$up-btn-height: 34px;
$up-btn-height-lg: 40px;
$up-btn-height-xl: 46px;

/* Font variables */
/* Franklin Gothic demi for headings and Arial for the rest */
$h-font-family: "Roboto", sans-serif;

$main-font-family: "Roboto", sans-serif;
$modal-font-size: 12px;
$navbar-font-size: 13px;
$h2-font-size: 19px;
$h3-font-size: 16px;

/* Other */
$up-curtain-z: 1003;
$modal-z: 10000;
$notification-z: 20000;
$img-path: "/img/";
$font-path: "/fonts/";

/* Border radius */
$border-radius-sm: 2px;
$border-radius: 3px;
$border-radius-lg: 6px;

/* Screen sizes */
$responsiveWidht1: 1420px;
$responsiveWidht2: 1300px;
$responsiveWidht3: 1168px;
$responsiveWidht4: 1024px;
$responsiveWidht5: 890px;
$responsiveWidht6: 700px;

/* TABS */
$tabsLgHeight: 60px;
$tabsMdHeight: 50px;
$tabsSmHeight: 40px;
