.mentorship__userFeedbacks {
  .tableWrapper {
    .feedback-actions {
      text-align: right;
    }
  }

  .headerTitle-wrap {
    display: inline-flex;
  }
}

.mentorship__loader {
  padding: 20% 40%;
}
