@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.BulkAddModal__dropzone {
  margin-top: 15px;
  .dropzone-main {
    background: white;
    border-radius: 5px;
    border: 2px dashed #0087f7;
    border-image: none;
    max-width: 500px;
    min-height: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    text-align: center;
    &:focus {
      border: 2px dashed #0087f7 !important;
    }
  }
  .dropzone-placeholder {
    padding-top: 80px;
    font-size: 16px;
  }

  .diabled {
    background: $grey-3;
  }
}

.BulkAddModal__download-sample-Btn {
  margin-right: 6px;
}

.BulkAddModal__errorzone {
  padding-top: 10px;
  .alert-danger {
    background-color: #e74c3c;
  }
}
