@import './color';


.card__statCard{
    background-color: $white;
    margin-bottom: 20px;
    width: 100%;
    -webkit-box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
    -moz-box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
    box-shadow: 0 0 14px 0 rgba(53,64,82,.05);
    padding: 27px 24px 34px 24px;
    
    .header{
        text-align: center;
        .leftboxs{
            text-align: right;
            h3{
                display: inline-block;
                float: left;
                text-transform: capitalize;
                font-family: 'robotoregular';
                border-radius: 4px;
                color: white;
                color: $black;
                padding-bottom: 32px;
            }
            .balance{
                
                display: inline-block;
                float: right;
                box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.05);
                border-radius: 10px;    
                font-size: 12px;
                color: $white;
                padding: 4px 10px;
                p{
                    font-family: 'robotoregular';
                }
            }
        }
    }
    .stats{
        padding-top:20px;
        h6{
            color: $black;
            padding-bottom:10px; 
            font-family: 'robotomedium';
            text-transform: capitalize
        }
    }
    .leavecontent{
        padding-top: 10px;
        border-top: 1px solid #eee;
        .leave{
            padding: 20px 0px;
            h6{
                display: inline-block;
                float: left;
                font-family: 'robotoregular';
                padding-top: 5px;
            }
            .balance{
                float: right;
                box-shadow: 0 0 14px 0 rgba(53, 64, 82, 0.05);
                border-radius: 10px;    
                font-size: 12px;
                color: $white;
                padding: 4px 10px;
                background-color: #eee;
                p{
                    color: black
                }
    
            }
        }
    }
    
    
}
.card0{
    .header{
        .balance{
        background-color: #00c284;
        }
    }
}
.card1{
    .header{
        .balance{
            background-color: #3d9feb;
        }
    }
    
}
.card2{
    .header{
        .balance{
            background-color: #e95164;
        }
    }
    
    
}
.card3{
   .header{
    .balance{
        background-color: #f69a06;
    }
   }
    
}