// @import '../Utils/colors';
// @import '../Utils/mixins';
// @import '../Utils/animations';
@import "../../../assets/css/color";

$height-sm: 24px;
$smTextSize: 12px;
$height: 34px;
$mdTextSize: 14px;
$height-lg: 44px;
$lgTextSize: 18px;

$color: $grey-13;
$placeholderColor: $grey-8;
$borderColor: $grey-6;
$borderColorFocus: $bright-blue;
$borderColorError: $red;
$borderColorWarning: $orange;
$borderColorSuccess: $green;
$borderColorDisabled: $borderColor;

.Input {
  position: relative;
  height: $height;
  vertical-align: middle;
}

.Input__input {
  display: block;
  width: 100%;
  border: 1px solid $borderColor;
  box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
  border-radius: 2px;
  outline: none;
  color: $color;
  font-weight: 400;
  padding: 0 10px;
  box-sizing: border-box;
  transition: all ease-out 200ms;
}

.Input--white .Input__input {
  background-color: $white;
}

.Input--grey-2 .Input__input {
  background-color: $grey-2;
}

.Input--sm.Input,
.Input--sm .Input__input {
  height: $height-sm;
}
.Input--sm .Input__input {
  line-height: $height-sm;
  font-size: $smTextSize;
}

.Input--md.Input,
.Input--md .Input__input {
  height: $height;
}
.Input--md .Input__input {
  line-height: $height;
  font-size: $mdTextSize;
}

.Input--lg.Input,
.Input--lg .Input__input {
  height: $height-lg;
}
.Input--lg .Input__input {
  line-height: $height-lg;
  font-size: $lgTextSize;
}

.Input--focus .Input__input,
.Input__input:focus {
  border-color: $borderColorFocus;
}

.Input--error .Input__input {
  border-color: $borderColorError;
}

.Input--warning .Input__input {
  border-color: $borderColorWarning;
}

.Input--success .Input__input {
  border-color: $borderColorSuccess;
}

.Input--disabled .Input__input {
  color: $grey-10;
  font-style: italic;
  background-color: $grey-4;
  border-color: $borderColorDisabled;
}

.Input__input::-webkit-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input__input::-moz-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input__input:-moz-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input__input:-ms-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input__input::-ms-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input__input::placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Input--inline .Input__input {
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  border: transparent;
  border-bottom: 1px solid $borderColor;
  background-color: transparent;

  &:focus {
    border-bottom-color: $bright-blue;
  }
}

.Input--noborder .Input__input {
  outline: none;
  box-shadow: none;
  border-radius: 0;
  border: transparent;
}

.Input--has-icon {
  position: relative;
}

.Input--has-icon .Input__input {
  padding-left: $height;
}

.Input--has-icon.Input--sm .Input__input {
  padding-left: $height-sm;
}

.Input--has-icon.Input--lg .Input__input {
  padding-left: $height-lg;
}

.Input--md .Input__clear-icon {
  font-size: $mdTextSize;
  line-height: $height;
}

.Input--sm .Input__clear-icon {
  font-size: $smTextSize;
  line-height: $height-sm;
}

.Input--lg .Input__clear-icon {
  font-size: $lgTextSize;
  line-height: $height-lg;
}

.Input__icon {
  position: absolute;
  line-height: $height;
  height: $height;
  width: $height;
  text-align: center;
  color: $borderColor;
  left: 0;
}

.Input--sm .Input__icon {
  line-height: $height-sm;
  font-size: $smTextSize;
  height: $height-sm;
  width: $height-sm;
}

.Input--lg .Input__icon {
  line-height: $height-lg;
  font-size: $lgTextSize;
  height: $height-lg;
  width: $height-lg;
}

.Input__clear-icon {
  position: absolute;
  line-height: $height;
  height: $height;
  width: $height;
  text-align: center;
  color: $borderColor;
  right: 0;
  top: 0;
  cursor: pointer;
  transition: all ease-out 200ms;
  visibility: hidden;
  opacity: 0;
}

.Input__clear-icon--visible {
  visibility: visible;
  opacity: 1;
}

.Input__clear-icon:hover {
  color: $grey-10;
}
