@import "./color";

.normal-btn {
  padding: 15px 25px;
  width: 100%;
  height: 50px;
  margin: 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: bold;
  background-color: $buttonColorOne;
  border: none;
  font-family: "robotobold";
  letter-spacing: 1px;
  font-size: 18px;
  border-radius: 4px;
  &:hover {
    background-color: $buttonColorOneHover;
    transition: 0.3s;
  }
  &:focus {
    box-shadow: none;
    border-color: none;
    background-color: $buttonColorOne;
    border-color: $buttonColorOne;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $buttonColorOne;
    border-color: $buttonColorOne;
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}

.modal-btn-submit {
  text-transform: uppercase;
  font-weight: bold;
  background-color: $buttonColorOne;
  border: none;
  font-family: "robotobold";
  border-radius: 4px;
  margin-right: 18px;
  &:hover {
    background-color: $buttonColorOneHover;
    transition: 0.3s;
  }
  &:focus {
    box-shadow: none;
    border-color: none;
    background-color: $buttonColorOne;
    border-color: $buttonColorOne;
  }
  &:not(:disabled):not(.disabled):active {
    background-color: $buttonColorOne;
    border-color: $buttonColorOne;
    box-shadow: none;
  }
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
}
