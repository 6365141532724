@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";

.Paginator {
  display: flex;
}

.Paginator--align-center .ButtonGroup {
  margin: 0 auto;
}

.Paginator--align-left .ButtonGroup {
  margin-right: auto;
}

.Paginator--align-right .ButtonGroup {
  margin-left: auto;
}

.Paginator__Button.btn-white {
  color: $bright-blue;
}
