.vacancies__list {
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }
  .vacancies__application-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }
  .vacancies__name {
    .badge {
      margin-left: 5px;
    }
  }
}
