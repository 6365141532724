.HrmSurveyAddQuestions__wrapper {
  margin-top: 35px;
  .required-label:after {
    color: #d00;
    content: "*";
    margin-left: 2px;
    top: 7px;
  }
}

.HrmSurveyAddQuestions__header {
  text-align: center !important;
}

.HrmSurveyAddQuestions__question-card {
  border-right: 1px solid grey;

  .question-required-box {
    .Input__input {
      width: 30px;
    }
  }
}

.HrmSurveyAddQuestions__action-btns {
  text-align: center !important;
}
