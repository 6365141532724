@import '../css//color';

.input-field{
    padding-bottom: 20px;
    position: relative;
    
    label{
        text-align: left;
        padding-bottom: 0px;
        font-size: 12px

    }
    .input-container{
        .form-control{
            background-color: $backgroundColor;
            padding: 10px 15px;
            border: none;
            font-size: 14px;
            height: 40px;
            box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
            border: none;
            // border:1px solid $backgroundColor;
            &:focus {
            box-shadow: none;
            
            }
        }
        ::placeholder{
            opacity: .2;
        }
        span{
            font-size: 12px;
            color: $errorColor;
        }
        .redline{
            border:1px solid $errorColor;
        }
        
    }
    select{
        display: block;
        font-size: 12px;
        padding: 0px 15px;
        height: 40px;
        width: 100%;
        max-width: 100%;
        background-color: $backgroundColor;
        box-sizing: border-box;
        border: none;
        box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
        background-repeat: no-repeat, repeat;
        background-position: right 10px top 50%, 0 0;
        background-size: .65em auto, 100%;
        &:focus {
            outline: none;
        }
        
    }
    
    select:after{
        position: absolute;
        content: "";
        top: 14px;
        right: 10px;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #fff transparent transparent transparent;
    }
    
    
}



