@import "../../../assets/css/color";
@import "../../../assets/css/variable";

.Login__form {
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 7, 71, 0.85)),
    url("../../../assets/img/backimage1.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  // background-color: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 7, 71, 0.85));
  .login-form-card {
    margin: 20% 0 0 25%;
    width: 50%;
  }
  .login-form-header {
    color: $white;
    padding-bottom: 10px;
  }

  .Login__submit {
    button {
      width: 100%;
    }
    .Button__loader-wrap {
      padding-left: 36px;
    }
  }
}
