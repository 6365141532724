@import "./color";

.appHeader__main-content-header {
  .dropdown-toggle:after {
    left: 1px;
    top: 2px;
    position: relative;
  }
  .dropdown-toggle {
    background-color: $HeaderColor;
    border-color: $HeaderColor;
  }

  .dropdown-item:active {
    background-color: $dark-blue;
  }
  background-color: $HeaderColor;
  color: $white;
  padding: 15px 45px;
  -webkit-box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
  -moz-box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
  box-shadow: rgba(53, 64, 82, 0.05) 0px 0px 14px 0px;
  text-align: right;
  .search {
    .form-control {
      width: 50%;
      border: none;
      border-bottom: 1px solid $grey;
      font-family: "robotomedium";
      height: 30px;
      padding: 9px 30px;
      color: #b9b9b9;

      &:focus {
        box-shadow: none;
      }
      &::placeholder {
        color: #b9b9b9;
      }
    }
    .svg-inline--fa {
      position: absolute;
      top: 17%;
      left: 2%;
      color: #b9b9b9;
    }
  }
  .rightWrapper {
    .notification {
      display: inline-block;
      position: relative;
      a {
        img {
          width: 32px;
        }
      }
      .buble {
        position: absolute;
        width: 15px;
        height: 15px;
        background-color: $notificationColor;
        top: 0;
        right: 0;
        border-radius: 100%;
        text-align: center;
        font-size: 10px;
        span {
          font-family: "robotobold";
        }
      }
    }
    .logoutbtn {
      display: inline-block;
      float: right;
      padding-left: 20px;
      position: relative;
      a {
        img {
          width: 32px;
        }
      }
      .bubleBox {
        display: none;
        position: absolute;
        background-color: $sidebarColor;
        padding: 10px 15px;
        right: 5px;
        top: 38px;
        border-radius: 6px;
        img {
          position: absolute;
          right: 7px;
          top: -9px;
          width: 12px;
        }
        a {
          font-size: 14px;
          font-family: "robotomedium";
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }
  }
}

.appHeader__main-content-header .rightWrapper .logoutbtn:hover .bubleBox {
  display: block;
}
