@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";

.Tabs {
  position: relative;
  display: flex;
  overflow: hidden;
}

.Tabs__selected-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  transition: all ease 200ms;
}

.Tabs--no-transition .Tabs__selected-slider {
  transition: left ease 0ms, width ease 0ms;
}

.Tabs--no-flex {
  display: inline-block;
}

.Tabs--no-flex .Tab {
  display: inline-block;
}

/* WHITE */
.Tabs--white {
  background-color: $white;
}

.Tabs--white .Tabs__selected-slider {
  background-color: $blue;
}

/* BLUE */
.Tabs--blue {
  background-color: $blue;
}

.Tabs--blue .Tabs__selected-slider {
  background-color: $white;
}

/* BRIGHT BLUE */
.Tabs--bright-blue {
  background-color: $bright-blue;
}

.Tabs--bright-blue .Tabs__selected-slider {
  background-color: $white;
}

/* BRIGHT BLUE */
.Tabs--medium-purple {
  background-color: $medium-purple;
}

.Tabs--medium-purple .Tabs__selected-slider {
  background-color: $white;
}

/* SIZES */
.Tabs--lg {
  height: $tabsLgHeight;
}
.Tabs--md {
  height: $tabsMdHeight;
}

.Tabs--sm {
  height: $tabsSmHeight;
}
