// #03dac6

//color variables

$primary: #e95164;
$secondary: #112329;
$sidebarColor: #152d35; //#152d35
$HeaderColor: #171a24;
$backgroundColor: #f7f9fc;
$errorColor: #b00020;
$notificationColor: #e74c3c;
$white: #fff;
$black: #000;
$grey: #eee;
$iconColor: #bfbebe;

$textColorSecondary: #ff5469;
$textColorGrey: #eee;
$buttonColorOne: #e95164;
$buttonColorOneHover: #ff5469; //#27803b
$buttonColorTwo: #b00020;

// Modal Headers
$modalHeaderPrimary: #e5e5e5;

// THIS FILE IS GENERATED ON BUILD!

// color variables

// BLUE:
$dark-blue: #052a4f;
$blue: #002f65;
$medium-blue: #3367c0;
$bright-blue: #4a90e2;
$super-light-blue: #e4edf7;
$info: #17a2b8;

// PURPLE:
$dark-purple: #4e0065;
$purple: #721a94;
$medium-purple: #9933c0;
$bright-purple: #b254e0;
$super-light-purple: #e1c2f1;

// TURCOSE:
$dark-turcose: #32678b;
$turcose: #01687d;
$medium-turcose: #2eaa9b;
$bright-turcose: #50e3c2;

// RED:
$dark-red: #912727;
$red: #d33f42;
$super-light-red: #f6e8e8;

// ORANGE:
$brown: #713501;
$dark-orange: #a65f03;
$orange: #f58523;
$bright-orange: #f5a623;
$super-light-orange: #f3e6cf;

// YELLOW:
$yellow: #f8e71c;
$super-light-yellow: #feffe7;

// GREEN:
$dark-green: #0f550f;
$green: #2b7b2b;
$medium-green: #3d993d;
$bright-green: #5cb85c;
$super-light-green: #ecf4e4;

// GREY:
$black: #1e252e;
$grey-13: #2f3947;
$grey-12: #3e4957;
$grey-11: #4b5562;
$grey-10: #6b7c93;
$grey-9: #9baabe;
$grey-8: #a4b3c7;
$grey-7: #b2becf;
$grey-6: #cad2dd;
$grey-5: #d3dae4;
$grey-4: #e4eaf0;
$grey-3: #e9eef3;
$grey-2: #edf1f5;
$grey-1: #f6f9fb;
$white: #fff;

// MARKETING:
$marketing-general: #721a94; // references to $purple
$form-color: #721a94; // references to $purple
$landing-page-color: #721a94; // references to $purple
$email-campaign-color: #9933c0; // references to $medium-purple
$advertising-color: #9933c0; // references to $medium-purple
$website-visits-color: #4a90e2; // references to $bright-blue

// SALES:
$sales-general: #002f65; // references to $blue
$order-color: #2b7b2b; // references to $green
$opportunity-color: #002f65; // references to $blue
$appointment-color: #3367c0; // references to $medium-blue
$activity-color: #4a90e2; // references to $bright-blue

// GENERAL:
$link-color: #4a90e2; // references to $bright-blue
$headline-color: #2f3947; // references to $grey-13
$subtitle-color: #6b7c93; // references to $grey-10

// Color classes

// BLUE:
.color-dark-blue {
  color: #052a4f;
}
.color-blue {
  color: #002f65;
}
.color-medium-blue {
  color: #3367c0;
}
.color-bright-blue {
  color: #4a90e2;
}
.color-super-light-blue {
  color: #e4edf7;
}

// PURPLE:
.color-dark-purple {
  color: #4e0065;
}
.color-purple {
  color: #721a94;
}
.color-medium-purple {
  color: #9933c0;
}
.color-bright-purple {
  color: #b254e0;
}
.color-super-light-purple {
  color: #e1c2f1;
}

// TURCOSE:
.color-dark-turcose {
  color: #32678b;
}
.color-turcose {
  color: #01687d;
}
.color-medium-turcose {
  color: #2eaa9b;
}
.color-bright-turcose {
  color: #50e3c2;
}

// RED:
.color-dark-red {
  color: #912727;
}
.color-red {
  color: #d33f42;
}
.color-super-light-red {
  color: #f6e8e8;
}

// ORANGE:
.color-brown {
  color: #713501;
}
.color-dark-orange {
  color: #a65f03;
}
.color-orange {
  color: #f58523;
}
.color-bright-orange {
  color: #f5a623;
}
.color-super-light-orange {
  color: #f3e6cf;
}

// YELLOW:
.color-yellow {
  color: #f8e71c;
}
.color-super-light-yellow {
  color: #feffe7;
}

// GREEN:
.color-dark-green {
  color: #0f550f;
}
.color-green {
  color: #2b7b2b;
}
.color-medium-green {
  color: #3d993d;
}
.color-bright-green {
  color: #5cb85c;
}
.color-super-light-green {
  color: #ecf4e4;
}

// GREY:
.color-black {
  color: #1e252e;
}
.color-grey-13 {
  color: #2f3947;
}
.color-grey-12 {
  color: #3e4957;
}
.color-grey-11 {
  color: #4b5562;
}
.color-grey-10 {
  color: #6b7c93;
}
.color-grey-9 {
  color: #9baabe;
}
.color-grey-8 {
  color: #a4b3c7;
}
.color-grey-7 {
  color: #b2becf;
}
.color-grey-6 {
  color: #cad2dd;
}
.color-grey-5 {
  color: #d3dae4;
}
.color-grey-4 {
  color: #e4eaf0;
}
.color-grey-3 {
  color: #e9eef3;
}
.color-grey-2 {
  color: #edf1f5;
}
.color-grey-1 {
  color: #f6f9fb;
}
.color-white {
  color: #fff;
}

// MARKETING:
.color-marketing-general {
  color: #721a94;
}
.color-form-color {
  color: #721a94;
}
.color-landing-page-color {
  color: #721a94;
}
.color-email-campaign-color {
  color: #9933c0;
}
.color-advertising-color {
  color: #9933c0;
}
.color-website-visits-color {
  color: #4a90e2;
}

// SALES:
.color-sales-general {
  color: #002f65;
}
.color-order-color {
  color: #2b7b2b;
}
.color-opportunity-color {
  color: #002f65;
}
.color-appointment-color {
  color: #3367c0;
}
.color-activity-color {
  color: #4a90e2;
}

// GENERAL:
.color-link-color {
  color: #4a90e2;
}
.color-headline-color {
  color: #2f3947;
}
.color-subtitle-color {
  color: #6b7c93;
}

// Variable with all colors to iterate
// $colors: (dark-blue, #052A4F, #ffffff), (blue, #002f65, #ffffff), (medium-blue, #3367C0, #ffffff), (bright-blue, #4A90E2, #ffffff), (super-light-blue, #E4EDF7, #000), (dark-purple, #4E0065, #ffffff), (purple, #721A94, #ffffff), (medium-purple, #9933C0, #ffffff), (bright-purple, #B254E0, #ffffff), (super-light-purple, #E1C2F1, #000), (dark-turcose, #32678B, #ffffff), (turcose, #01687D, #ffffff), (medium-turcose, #2EAA9B, #ffffff), (bright-turcose, #50E3C2, #000), (dark-red, #912727, #ffffff), (red, #D33F42, #ffffff), (super-light-red, #F6E8E8, #000), (brown, #713501, #ffffff), (dark-orange, #A65F03, #ffffff), (orange, #F58523, #ffffff), (bright-orange, #F5A623, #ffffff), (super-light-orange, #F3E6CF, #000), (yellow, #F8E71C, #000), (super-light-yellow, #FEFFE7, #000), (dark-green, #0F550F, #ffffff), (green, #2B7B2B, #ffffff), (medium-green, #3D993D, #ffffff), (bright-green, #5cb85c, #ffffff), (super-light-green, #ECF4E4, #000), (black, #1E252E, #ffffff), (grey-13, #2F3947, #ffffff), (grey-12, #3E4957, #ffffff), (grey-11, #4B5562, #ffffff), (grey-10, #6B7C93, #ffffff), (grey-9, #9BAABE, #ffffff), (grey-8, #A4B3C7, #ffffff), (grey-7, #B2BECF, #ffffff), (grey-6, #CAD2DD, #000), (grey-5, #D3DAE4, #000), (grey-4, #E4EAF0, #000), (grey-3, #E9EEF3, #000), (grey-2, #EDF1F5, #000), (grey-1, #F6F9FB, #000), (white, #FFF, #000)

//#00c284 // #3d9feb  //#e95164 //#f69a06
