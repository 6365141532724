.AddCandidateModal__modal {
  .resume-upload {
    .Input__input {
      padding: 0;
      line-height: 27px;
    }
  }
  .Button__loader-wrap {
    padding-left: 36px;
  }
}
