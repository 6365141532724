@import "../../../../assets/css/color";

$height: 34px;

$color: $grey-13;
$placeholderColor: $grey-8;
$borderColor: $grey-6;
$borderColorFocus: $bright-blue;
$borderColorError: $red;
$borderColorWarning: $orange;
$borderColorSuccess: $green;
$borderColorDisabled: $borderColor;

.Textarea__input {
  display: block;
  width: 100%;
  border: 1px solid $borderColor;
  box-shadow: inset 1px 1px 2px 0 rgba($black, 0.1);
  background-color: $white;
  border-radius: 2px;
  height: $height * 3;
  line-height: $height - 10px;
  outline: none;
  color: $color;
  font-size: 14px;
  font-weight: 400;
  padding: 5px 10px;
  box-sizing: border-box;
  transition: all ease-out 200ms;
  resize: none;
}

.Textarea--focus .Textarea__input,
.Textarea__input:focus {
  border-color: $borderColorFocus;
}

.Textarea--error .Textarea__input {
  border-color: $borderColorError;
}

.Textarea--warning .Textarea__input {
  border-color: $borderColorWarning;
}

.Textarea--success .Textarea__input {
  border-color: $borderColorSuccess;
}

.Textarea--disabled .Textarea__input {
  color: $grey-10;
  font-style: italic;
  background-color: $grey-4;
  border-color: $borderColorDisabled;
}

.Textarea__input::-webkit-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea__input::-moz-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea__input:-moz-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea__input:-ms-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea__input::-ms-input-placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea__input::placeholder {
  color: $placeholderColor;
  font-style: italic;
  line-height: $height;
}

.Textarea--noborder .Textarea__input {
  outline: none;
  box-shadow: none;
  border-radius: 0;
  border: transparent;
}

.Textarea--auto-height .Textarea__input {
  overflow: hidden;
  height: auto;
  min-height: $height;
  line-height: $height;
  padding-bottom: 0;
  padding-top: 0;
}
