@import "../../../../../../assets/css/color";
@import "../../../../../../assets/css/variable";

.application__candidate {
  .application__review-card {
    margin-top: 26px;
  }

  .application__startRating {
    text-align: right;
  }

  .application-action-btn {
    margin-right: 6px;
  }

  .application__detailCard {
    display: inline-flex;
  }

  .row-breaker {
    border-right: 1px solid $grey-5;
  }
}

.infoModall__infoModal {
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }
}
