/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

@font-face {
  font-family: "robotomedium";
  src: url("./assets/fonts/roboto-medium-webfont.eot");
  src: url("./assets/fonts/roboto-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-medium-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-medium-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("./assets/fonts/roboto-regular-webfont.eot");
  src: url("./assets/fonts/roboto-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-regular-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-regular-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-regular-webfont.svg#robotoregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotothin";
  src: url("./assets/fonts/roboto-thin-webfont.eot");
  src: url("./assets/fonts/roboto-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-thin-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-thin-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-thin-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-thin-webfont.svg#robotothin") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotobold";
  src: url("./assets/fonts/roboto-bold-webfont.eot");
  src: url("./assets/fonts/roboto-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-bold-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-bold-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotolight";
  src: url("./assets/fonts/roboto-light-webfont.eot");
  src: url("./assets/fonts/roboto-light-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-light-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-light-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-light-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-light-webfont.svg#robotolight") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotomedium";
  src: url("./assets/fonts/roboto-medium-webfont.eot");
  src: url("./assets/fonts/roboto-medium-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-medium-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-medium-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-medium-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-medium-webfont.svg#robotomedium") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoblack";
  src: url("./assets/fonts/roboto-black-webfont.eot");
  src: url("./assets/fonts/roboto-black-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-black-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-black-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-black-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-black-webfont.svg#robotoblack") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotobold";
  src: url("./assets/fonts/roboto-bold-webfont.eot");
  src: url("./assets/fonts/roboto-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/roboto-bold-webfont.woff2") format("woff2"),
    url("./assets/fonts/roboto-bold-webfont.woff") format("woff"),
    url("./assets/fonts/roboto-bold-webfont.ttf") format("truetype"),
    url("./assets/fonts/roboto-bold-webfont.svg#robotobold") format("svg");
  font-weight: normal;
  font-style: normal;
}
