@import "./color";

.landing-page {
  background-image: linear-gradient(rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0.74)),
    url("../img/bacground-2.png");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  .landing-wrapper {
    margin-top: 20%;
    a {
      .card {
        margin-bottom: 40px;
        background-color: #3483de;
        width: 90%;
        border: none;
        border-radius: 4px;
        p {
          font-family: "robotobold";
          font-size: 16px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
        &:hover {
          background-color: #4a90e2;
          transition: 0.3s;
        }
      }
    }
  }
}
