.ButtonGroup {
  display: inline-flex;
}

.ButtonGroup--block {
  display: flex;
}

/* BUTTON STYLE */
.ButtonGroup .Input,
.ButtonGroup .Button {
  flex: 1;
}

.ButtonGroup .Input:not(:last-child),
.ButtonGroup .Button:not(:last-child) {
  border-right: 0;
}

.ButtonGroup .Input:first-child:not(:last-child) .Input__input,
.ButtonGroup .Button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.ButtonGroup .Input:last-child:not(:first-child) .Input__input,
.ButtonGroup .Button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ButtonGroup .Input:not(:first-child):not(:last-child) .Input__input,
.ButtonGroup .Button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ButtonGroup .ButtonGroup__no-flex {
  flex: 0;
}
