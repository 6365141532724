.AddVacancyModal__modal {
  .jd-upload {
    .Input__input {
      padding: 0;
      line-height: 27px;
    }
  }
  .ck-editor__editable {
    min-height: 200px;
  }

  .AddVacancyModal__jd-image {
    overflow: hidden;
    a {
      color: #007bff;
    }
  }

  .AddVacancyModal__delete-jdImage {
    margin-left: 5px;
  }
}

.upload-wrap {
  position: relative;
}

.upload-btn {
  position: absolute;
  left: 0;
  opacity: 0;
}
