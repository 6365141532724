@import "../../../../../../assets/css/color";
@import "../../../../../../assets/css/variable";

.Vacancy__external {
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 7, 71, 0.85)),
    url("../../../../../../assets/img/backimage1.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  .loading-vacancy-card {
    width: 22%;
    margin-left: 40%;
    margin-top: 40%;
  }
  .vacancy-card {
    margin-top: 2%;
    .card {
      min-height: 98vh;
      margin-bottom: 2%;
    }

    .header-actions {
      text-align: right;
      padding-top: 4px;
      .vacancy-file-view {
        margin-right: 2px;
      }
    }

    .header-name {
      padding-left: 6px;
    }
  }
  .vacancy-header {
    color: $white;
    padding-bottom: 10px;
  }
  .Login__submit {
    button {
      width: 100%;
    }
    .Button__loader-wrap {
      padding-left: 36px;
    }
  }
  .resume-upload {
    .Input__input {
      padding: 0;
    }
  }

  .Vacancy__jobDescription {
    padding-top: 10px;
  }

  .Vacancy__jdImageFile {
    img {
      height: 100%;
      width: 100%;
    }
  }

  .company-header-name {
    display: inline-flex;
    .company-name {
      padding-top: 10px;
    }
  }

  .job-header {
    background-color: $white;
  }
}
