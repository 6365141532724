@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.AddItemModal__modal {
  .AddItemModal__warrantyDate {
    cursor: pointer;
    color: #0c7efc;
  }
  .react-datepicker-wrapper {
    display: initial;

    .date-error {
      border: 1px solid #dc3545;
    }
  }

  .AddItemModal__notesBy {
    color: $grey-10;
  }

  .image-upload {
    .Input__input {
      padding: 0;
      line-height: 27px;
    }
  }

  .AddItemModal__dropzone {
    margin-top: 15px;
    .dropzone-main {
      background: white;
      border-radius: 5px;
      border: 2px dashed #0087f7;
      border-image: none;
      max-width: 500px;
      min-height: 200px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      text-align: center;
      &:focus {
        border: 2px dashed #0087f7 !important;
      }
    }
    .dropzone-placeholder {
      padding-top: 80px;
      font-size: 16px;
    }

    .diabled {
      background: $grey-3;
    }
  }

  .AddItemModal__file-list {
    padding-top: 10px;
    .removeItem-file-icon {
      color: $red;
      margin-left: 15px;
    }
    ol {
      padding-top: 10px;
      a {
        color: #007bff;
      }
    }
  }

  .Button__loader-wrap {
    padding-left: 36px;
  }

  .create-type {
    cursor: pointer;
    font-size: 12px;
    color: $bright-blue;
  }
}
