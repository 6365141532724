@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.EditUser__edit-user-wrapper {
  .EditUser__loader {
    padding: 20% 40%;
  }
  .EditUser__userFrom {
    margin-top: 36px;
    .AddUserStepOne__formOne,
    .AddUserStepTwo__formTwo,
    .AddUserStepThree__formThree {
      .react-datepicker-wrapper {
        display: initial;

        .date-error {
          border: 1px solid #dc3545;
        }
      }

      .AddUserStepOne__buttonGroup,
      .AddUserStepTwo__buttonGroup,
      .AddUserStepThree__buttonGroup {
        text-align: right;
      }

      .create-designation {
        cursor: pointer;
        font-size: 12px;
        color: $bright-blue;
      }
    }
  }
}
