@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";

.RadioListItem {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.RadioListItem > .Label {
  margin: 0 0 0 10px;
}

.RadioItem {
  background: $grey-4;
  border-radius: 100%;
  display: flex;
  cursor: pointer;

  .radio-check {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.RadioItem--xs {
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 10px;
}

.RadioItem--sm {
  width: 20px;
  height: 20px;
  font-size: 11px;
  line-height: 11px;
}

.RadioItem--md {
  width: 26px;
  height: 26px;
  font-size: 16px;
}

.RadioItem--lg {
  width: 30px;
  height: 30px;
  font-size: 18px;
}

.RadioItem--xl {
  width: 36px;
  height: 36px;
  font-size: 22px;
}

.RadioItem--checked {
  background: $bright-blue;

  .radio-check {
    color: $white;
  }
}

.RadioItem--disabled {
  background-color: lighten($grey-4, 6%);
  border-color: lighten($grey-4, 6%);
  color: $white;
  text-decoration: none;
}

.RadioItem--disabled.RadioItem--checked {
  background-color: lighten($bright-blue, 20%);
  border-color: lighten($bright-blue, 20%);
  color: $white;
  text-decoration: none;
}
