.InventoryItemInfo__wrapper {
  object {
    margin-right: 4px;
    border: 1px solid grey;
    cursor: pointer;
  }

  .view-file {
    position: absolute;
  }

  .InventoryItemInfo__file-view-header {
    background-color: #454547;
    height: 24px;
    margin-right: 4px;
    position: absolute;
    text-align: center;
    width: 100px;
  }

  li {
    list-style: disc;
  }

  .form-group {
    label {
      font-weight: 600;
    }
  }
}
