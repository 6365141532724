@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";

.inventory__itemSearch {
  display: inline-flex;
  padding-right: 8px;
}

.Inventory__itemCost {
  text-align: right;
  padding-right: 35px !important;
}

.inventory__items {
  .tableWrapper {
    .align-center {
      text-align: center;
    }
  }

  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }
}

.Inventory__item-actions {
  text-align: right;

  .Button {
    margin-right: 2px;
  }
}

.inventory__loader {
  padding: 20% 40%;
}

.inventory__addItem-btn {
  margin-right: 8px;
}

.inventory__option-btn {
  background-color: $backgroundColor !important;
  border-color: $backgroundColor !important;
  color: $dark-blue !important;
  box-shadow: none !important;
}

.inventory__option-menu {
  .dropdown-item:active {
    background-color: $dark-blue;
  }
}
