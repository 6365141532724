@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.HrmSurveyFeedback__wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 7, 71, 0.85)),
    url("../../../../../assets/img/backimage1.jpg");
  // height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;

  .HrmSurveyFeedback__loader {
    padding: 40% 45%;
    height: 100vh;
  }

  .HrmSurveyFeedback__survey-card {
    margin-top: 2%;
    .card {
      min-height: 98vh;
      margin-bottom: 2%;
    }

    .survey-header {
      text-align: center;
      padding-bottom: 15px;
      border-bottom: 1px solid $grey-5;
    }

    .survey-question {
      padding-top: 15px;
    }

    .single-choice {
      span {
        position: relative;
        bottom: 2px;
        padding-left: 4px;
      }
    }

    .survey-footer {
      padding-left: 25px;
      .Button__loader-wrap {
        padding-left: 36px;
      }
    }

    .required-label:after {
      color: #d00;
      content: "*";
      margin-left: 2px;
      top: 7px;
    }
  }

  .alert-success {
    margin-top: 5%;
    text-align: center;
  }
}
