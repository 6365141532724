@import "../color";

.AddProjectModal__modal {
  .modal-header {
    background-color: $modalHeaderPrimary;
  }

  .form-row {
    padding-bottom: 14px;
  }
}
