.ResetMyPassword__form {
  .form-header {
    padding-bottom: 18px;
  }

  .ResetMyPassword__submit {
    text-align: center;
    .Button__loader-wrap {
      padding-left: 36px;
    }
  }
}
