$widget-width: auto;
$widget-height: 110px;

@mixin border-radius($radius: 5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -khtml-border-radius: $radius;
  border-radius: $radius;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

@mixin box-shadow($shadow) {
  -moz-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444')";
  filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#444444');
}

@mixin bootstrap-focus() {
  // $shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  border-color: #66afe9;
  outline: none;
  // @include box-shadow($shadow);
}

@mixin blur($pxls: 5) {
  filter: url(#blur);
  filter: blur($pxls + px);
  -webkit-filter: blur($pxls + px);
  -moz-filter: blur($pxls + px);
  -o-filter: blur($pxls + px);
  -ms-filter: blur($pxls + px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius='$pxls');
}

@mixin saturate($percent: 0%) {
  filter: saturate($percent);
  -webkit-filter: saturate($percent);
  -moz-filter: saturate($percent);
  -o-filter: saturate($percent);
  -ms-filter: saturate($percent);
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

@mixin caret-color($color) {
  border-top-color: $color;
  border-bottom-color: $color;
}

@mixin opacity($value) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{($value*100)})";
  filter: alpha(opacity=($value * 100));
  -moz-opacity: $value;
  -khtml-opacity: $value;
  opacity: $value;
}

@mixin opacity-i($value) {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{($value*100)})" !important;
  filter: alpha(opacity=($value * 100)) !important;
  -moz-opacity: $value !important;
  -khtml-opacity: $value !important;
  opacity: $value !important;
}

@mixin h2() {
  margin: 0;
  font-size: $h2-font-size;
  font-weight: normal;
  color: #333;
}

@mixin h3() {
  margin: 0;
  font-size: $h3-font-size;
  font-weight: normal;
  color: #333;
}

// UI MIXINS
@mixin curtain() {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #000;
  z-index: $modal-z - 1;
  @include opacity(0.3);
}

@mixin transfrom($args) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  transform: $args;
}

@mixin transfrom-origin($pos) {
  -webkit-transform-origin: $pos;
  -moz-transform-origin: $pos;
  -ms-transform-origin: $pos;
  transform-origin: $pos;
}

// Disable user select cross browser
@mixin disable-user-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// widget card
@mixin widget-card() {
  display: inline-block;
  width: $widget-width;
  height: $widget-height;
  padding: 10px 20px 10px 20px;
  margin: 0;
  vertical-align: top;
  border-right: 1px solid $table-borders;
  color: #000;
  background-color: #fff;

  .title,
  .value,
  .subtitle {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .title {
    text-transform: uppercase;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
  }

  .value {
    font-size: 22px;

    &.red {
      color: $upsales-red;
    }

    &.large {
      font-size: 32px;
    }
  }

  .score-indicator {
    position: relative;
    top: 3px;
    display: inline-block;
    font-size: 26px;
    margin-right: 2px;

    &.cold {
      color: #999;
    }

    &.medium {
      color: $light-blue;
    }

    &.hot {
      color: $upsales-green;
    }
  }
}

@mixin flex-container() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -moz-box-direction: normal;
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-box-align: start;
  -moz-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin flex-item() {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

@mixin user-select($value) {
  user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  -webkit-user-select: $value;
}

@mixin linear-gradient($from, $to, $deg: 90deg) {
  background: $from; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(
    $deg,
    $from,
    $to
  ); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($deg, $from, $to); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(
    $deg,
    $from,
    $to
  ); /* For Firefox 3.6 to 15 */
  background: linear-gradient($deg, $from, $to); /* Standard syntax */
}

@mixin space-component($className) {
  $types: ("margin", "padding");
  $directions: ("left", "right", "top", "bottom");
  $sizes: (
    "small": 4px,
    "medium": 6px,
    "large": 10px,
    "xlarge": 20px,
    "auto": auto,
  );
  @each $type in $types {
    @each $direction in $directions {
      @each $name, $size in $sizes {
        @if $size != "auto" && $type != "padding" {
          #{$className}--#{$type}-#{$direction}-#{$name} {
            #{$type}-#{$direction}: $size;
          }
        }
      }
    }
  }
}

@mixin border-component($className) {
  $directions: ("left", "right", "top", "bottom");
  $sizes: (
    "small": 1px,
    "medium": 3px,
    "large": 6px,
  );

  #{$className}--border {
    border-style: solid;
    border-width: 0;
  }

  @each $direction in $directions {
    @each $name, $size in $sizes {
      #{$className}--border-#{$direction}-#{$name} {
        border-#{$direction}-width: $size;
      }
    }
  }
  @each $name, $size in $sizes {
    #{$className}--border-#{$name} {
      border-width: $size;
    }
  }

  @each $color, $value in $colors {
    #{$className}--border-#{$color} {
      border-color: $value;
    }
  }
}
