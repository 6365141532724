@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";
@import "../../../../assets/css/mixins";
@import "../../../../assets/css/animations";

@mixin up-btn-hover($bg, $border, $color) {
  &.active,
  &:hover {
    background-color: darken($bg, 5%);
    border-color: darken($bg, 5%);
    text-decoration: none;
    color: $color;
  }

  &.btn-link.active,
  &.btn-link:hover {
    color: $bg;
    background-color: transparent;
  }

  &.btn-lined {
    &.active,
    &:hover:not(&[disabled]) {
      background-color: lighten($bg, 6%);
      border-color: lighten($bg, 6%);
      color: $color;
      text-decoration: none;
    }

    &.btn-white.active,
    &.btn-white:hover {
      color: $color;
    }
  }
}

@mixin up-btn-hover-classes() {
  &.btn-hover-grey {
    @include up-btn-hover($grey-10, $grey-10, $white);
  }

  &.btn-hover-blue {
    @include up-btn-hover($blue, $blue, $white);
  }

  &.btn-hover-bright-blue {
    @include up-btn-hover($bright-blue, $bright-blue, $white);
  }

  &.btn-hover-red {
    @include up-btn-hover($red, $red, $white);
  }

  &.btn-hover-green {
    @include up-btn-hover($green, $green, $white);
  }

  &.btn-hover-bright-green {
    @include up-btn-hover($bright-green, $bright-green, $white);
  }

  &.btn-hover-medium-green {
    @include up-btn-hover($medium-green, $medium-green, $white);
  }

  &.btn-hover-orange {
    @include up-btn-hover($orange, $orange, $white);
  }

  &.btn-hover-white {
    // 2020-06-24 Added this due to background white button should not be darken as other ones.
    &.active,
    &:hover {
      background-color: $white;
      border-color: $white;
      text-decoration: none;
      color: $black;
    }

    &.btn-link.active,
    &.btn-link:hover {
      color: $white;
      background-color: transparent;
    }

    &.btn-lined {
      &.active,
      &:hover:not(&[disabled]) {
        background-color: lighten($white, 6%);
        border-color: lighten($white, 6%);
        color: $black;
        text-decoration: none;
      }

      &.btn-white.active,
      &.btn-white:hover {
        color: $black;
      }
    }
  }

  &.btn-hover-medium-blue {
    @include up-btn-hover($medium-blue, $medium-blue, $white);
  }

  &.btn-hover-medium-purple {
    @include up-btn-hover($medium-purple, $medium-purple, $white);
  }
}

@mixin up-btn($bg, $border, $color) {
  background-color: $bg;
  border: 1px solid $border;
  color: $color;

  &.btn-gradient {
    background-image: linear-gradient(to top, $bg, lighten($bg, 10));
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    @include opacity(0.6);
    @include box-shadow(none);
  }

  &.active,
  &:hover {
    background-color: darken($bg, 5%);
    border-color: $border;
    text-decoration: none;
  }

  &.btn-link {
    background-color: transparent;
    color: $bg;
    box-shadow: none;
    border: none;
    text-shadow: none;

    &:hover {
      color: darken($bg, 25%);
    }

    &.btn-inline {
      padding: 0;
    }

    .Loader {
      g .progress-circle {
        stroke: $bg;
      }
    }
  }

  &.btn-lined {
    background-color: transparent;
    color: darken($bg, 10);
    text-shadow: none;

    &.active,
    &:hover {
      background-color: lighten($bg, 6%);
      border-color: lighten($bg, 6%);
      color: $color;
      text-decoration: none;
    }

    &.btn-white {
      border-color: $white;
      color: $white;

      &.active,
      &:hover {
        color: $color;
      }
    }

    .Loader {
      g .progress-circle {
        stroke: $bg;
      }
    }
  }

  &.has-subtitle {
    position: relative;
    line-height: 16px;
    text-align: left;
    padding-left: 6px;
    padding-right: 22px;

    .btn-subtitle {
      display: block;
      font-size: 9px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 10px;
      margin-top: 2px;
      text-align: left;
    }

    .fa-caret-up,
    .fa-caret-down {
      position: absolute;
      top: 12px;
      left: auto;
      right: 3px;
    }
  }

  .Button__loader-wrap {
    position: relative;
  }

  .Loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    width: 16px;
    height: 16px;

    g .progress-circle {
      stroke: $color;
    }
  }

  // .ng-enter {
  // 	@include transition(opacity ease-out 200ms);
  // 	opacity: 0;

  // 	&.ng-enter-active {
  // 		opacity: 1;
  // 	}
  // }

  // .ng-leave {
  // 	@include transition(opacity ease-out 200ms);
  // 	opacity: 1;

  // 	&.ng-enter-active {
  // 		opacity: 0;
  // 	}
  // }

  .badge {
    background-color: $white;
    color: $bg;
    text-shadow: none;
  }

  &.btn-sm {
    &.has-subtitle {
      padding: 0px 20px 0 6px;
      line-height: 13px;
      font-size: 12px;

      .btn-subtitle {
        line-height: 8px;
        font-size: 8px;
      }

      .fa-caret-up,
      .fa-caret-down {
        top: 8px;
      }
    }

    .Loader {
      margin-top: -7px;
      margin-left: -7px;
      width: 14px;
      height: 14px;
    }
  }

  &.btn-xs {
    height: $up-btn-height-xs;
    font-size: 11px;
    line-height: 11px;
    padding: 0 12px;

    .Loader {
      margin-top: -6px;
      margin-left: -6px;
      width: 12px;
      height: 12px;
    }
  }

  &.btn-lg {
    .Loader {
      margin-top: -12px;
      margin-left: -12px;
      width: 24px;
      height: 24px;
    }
  }

  &.main-action {
    width: 150px;
  }

  &.no-border {
    border-color: $bg;

    &:hover {
      border-color: $bg;
    }
  }

  &.no-margin {
    padding: 0;
    margin: 0;
    height: 1em;
    line-height: 1em;
  }

  @include up-btn-hover-classes();
}

.border-radius-lg {
  border-radius: $border-radius-lg !important;
}

.border-radius {
  border-radius: $border-radius !important;
}

.border-radius-sm {
  border-radius: $border-radius-sm !important;
}

.btn.btn-link,
.up-btn {
  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none !important;
    @include box-shadow(none);
  }
}

.up-btn {
  background-image: none;
  @include border-radius($border-radius-sm);
  @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.25));
  @include transition(all ease-out 200ms);
  padding: 0px 12px;
  font-size: 13px;
  height: $up-btn-height;
  line-height: $up-btn-height;
  font-weight: 400;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  background-color: $blue;
  border: 1px solid $blue;
  color: $white;

  &:focus,
  &.focus,
  &:active,
  &.active {
    outline: none !important;
    @include box-shadow(none);
  }

  &:hover {
    @include box-shadow(1px 1px 2px rgba(0, 0, 0, 0));
    text-decoration: none;
  }

  .caret {
    line-height: $up-btn-height;
  }

  // SIZES
  &.btn-sm,
  &.btn-group-sm > .btn {
    padding: 0 12px;
    font-size: 12px;
    line-height: 1.33;
    border-radius: $border-radius-sm;
    height: $up-btn-height-sm;

    &.caret {
      line-height: $up-btn-height-lg;
    }
  }

  &.btn-lg,
  &.btn-group-lg > .btn {
    padding: 10px 16px;
    font-size: 14px;
    line-height: 1.33;
    border-radius: $border-radius;
    height: $up-btn-height-lg;

    &.caret {
      line-height: $up-btn-height-lg;
    }
  }

  &.btn-xl,
  &.btn-group-xl > .btn {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: $border-radius;
    height: $up-btn-height-xl;

    &.caret {
      line-height: $up-btn-height-xl;
    }
  }

  &.dropdown-toggle {
    .fa.fa-caret-up,
    .fa.fa-caret-down {
      margin: 0 5px;
    }
  }

  &.no-shadow {
    @include box-shadow(none);
  }

  &.high-shadow {
    @include box-shadow(0 10px 20px rgba(0, 0, 0, 0.2));
  }

  &.no-border {
    border-color: $blue;
  }

  &.alpha-btn {
    background-color: transparent;
    border: 0;
    font-size: 24px;
    @include box-shadow(none);
  }

  &.btn-full-height {
    height: 45px;
    margin-top: 0px !important;
    min-width: 100px;
    padding: 0 20px;
    border-radius: 0;
  }

  // BUTTON COLORS

  &.btn-white {
    @include up-btn($white, $white, $black);
    &:hover {
      border-color: darken($white, 5%);
    }
  }

  &.btn-white-blue {
    @include up-btn($white, rgba($bright-blue, 0.3), $bright-blue);
  }

  &.btn-white-grey {
    @include up-btn($white, rgba($grey-10, 0.3), $grey-10);
  }

  &.btn-light-grey {
    @include up-btn($grey-4, $grey-4, $grey-10);

    &.btn-link {
      border-color: $grey-10;
    }
  }

  &.btn-super-light-grey {
    @include up-btn($grey-2, $grey-2, $grey-10);

    &.btn-link {
      border-color: $grey-10;
    }
  }

  &.btn-grey {
    // $bg, $border, $color
    @include up-btn($grey-10, $grey-10, $white);
    text-shadow: none;
  }

  &.btn-blue {
    @include up-btn($blue, $blue, $white);
    text-shadow: 1px 1px darken($blue, 5%);
  }

  &.btn-black {
    @include up-btn($black, $black, $white);
    text-shadow: 1px 1px darken($black, 5%);
  }

  &.btn-medium-blue {
    @include up-btn($medium-blue, $medium-blue, $white);
    text-shadow: 1px 1px darken($medium-blue, 5%);
  }

  &.btn-bright-blue {
    @include up-btn($bright-blue, $bright-blue, $white);
    text-shadow: 1px 1px darken($bright-blue, 5%);
  }

  &.btn-red {
    @include up-btn($red, $red, $white);
    text-shadow: 1px 1px darken($red, 5%);
  }

  &.btn-green {
    @include up-btn($green, $green, $white);
    text-shadow: 1px 1px darken($green, 5%);
  }

  &.btn-bright-green {
    @include up-btn($bright-green, $bright-green, $white);
    text-shadow: 1px 1px darken($bright-green, 5%);
  }

  &.btn-medium-green {
    @include up-btn($medium-green, $medium-green, $white);
    text-shadow: 1px 1px darken($medium-green, 5%);
  }

  &.btn-orange {
    @include up-btn($orange, $orange, $white);
    text-shadow: 1px 1px darken($orange, 5%);
  }

  &.btn-deep-green {
    @include up-btn($dark-green, $dark-green, $white);
    text-shadow: 1px 1px darken($dark-green, 5%);
  }

  &.btn-primary {
    @include up-btn($blue, $blue, $white);
    text-shadow: 1px 1px darken($blue, 5%);
  }

  &.btn-secondary {
    @include up-btn($bright-blue, $bright-blue, $white);
    text-shadow: 1px 1px darken($bright-blue, 5%);
  }

  &.btn-primary-action {
    @include up-btn($bright-blue, $bright-blue, $white);
    text-shadow: 1px 1px darken($bright-blue, 5%);
  }

  &.btn-secondary-action {
    @include up-btn($blue, $blue, $white);
    text-shadow: 1px 1px darken($blue, 5%);
  }

  &.btn-add-action {
    @include up-btn($bright-blue, $bright-blue, $white);
    text-shadow: 1px 1px darken($bright-blue, 5%);
  }

  &.btn-deep-blue {
    @include up-btn($dark-blue, $dark-blue, $white);
    text-shadow: 1px 1px darken($dark-blue, 5%);
  }

  &.btn-black {
    @include up-btn($black, $black, $white);
    text-shadow: 1px 1px darken($black, 5%);
  }

  &.btn-deep-purple {
    @include up-btn($dark-purple, $dark-purple, $white);
    text-shadow: 1px 1px darken($dark-purple, 5%);
  }

  &.btn-purple {
    @include up-btn($purple, $purple, $white);
    text-shadow: 1px 1px darken($purple, 5%);
  }

  &.btn-bright-purple {
    @include up-btn($bright-purple, $bright-purple, $white);
    text-shadow: 1px 1px darken($bright-purple, 5%);
  }

  &.btn-medium-purple {
    @include up-btn($medium-purple, $medium-purple, $white);
    text-shadow: 1px 1px darken($medium-purple, 5%);
  }

  &.btn-info {
    @include up-btn($info, $info, $white);
    text-shadow: 1px 1px darken($info, 5%);
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.btn-block.btn-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.uppercase {
    text-transform: uppercase;
  }
}

.btn.btn-flat {
  &:hover {
    color: $bright-blue;
  }
}
