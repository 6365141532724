.HrmSurveyList__wrapper {
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .HrmSurveyList__loader {
    padding: 20% 40%;
    height: 100vh;
  }

  .candidate__application-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }

  .HrmSurveyList__header-actions {
    display: inline-flex;
    .HrmSurveyList__filter {
      width: 200px;
      margin-right: 12px;
    }
  }

  .HrmSurveyList__archive-btn {
    min-width: 79px;
  }
}
