.InventoryGeneralReport__loader {
  padding: 20% 40%;
}

.InventoryGeneralReport__items {
  .report-action-btn {
    margin-right: 6px;
  }

  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .InventoryGeneralReport__item-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }

  .InventoryGeneralReport__itemCost {
    text-align: right !important;
    padding-right: 35px !important;
  }
}
