.HrmTurnDownPool__wrapper {
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .candidates__cv-card {
    padding-top: 15px;
  }

  .candidate__application-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }

  .list-sub-header-title {
    font-size: 16px;
    .badge {
      margin-right: 8px;
    }
  }

  .candidate__appliedPosition {
    a {
      color: #007bff;
    }
  }

  .HrmTurnDownPool__itemSearch {
    display: inline-flex;
    padding-right: 8px;
  }

  .list-sub-header-title {
    .badge {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.candidates__cvPool-resumePreview {
  height: 72vh;
}
