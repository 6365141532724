.InventoryDashboard__details {
  .dashboardCard {
    margin-top: 10px;
  }
  .InventoryDashboard__charts {
    padding-top: 45px;
  }

  .InventoryDashboard__loader {
    padding: 20% 40%;
  }
}
