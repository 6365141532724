@import "./color";

.sideNavBar__side-bar {
  background-color: $sidebarColor;
  width: 260px;
  float: left;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  .sidebar-menu {
    text-align: left;
    .navbar-nav {
      li {
        padding: 10px 30px;
        a {
          color: $grey;
          font-size: 14px;
          line-height: 1.5;
          text-decoration: none;
          font-family: "robotomedium";
          &:hover {
            text-decoration: none;
            color: $white;
          }
          svg {
            margin-right: 15px;
            font-size: 14px;
            width: 20px;
            height: 20px;
            opacity: 0.5;
          }
        }
        &:hover {
          text-decoration: none;
          background: rgba(0, 0, 0, 0.08);
          color: $white;
          cursor: pointer;
        }
      }
    }
    .user {
      max-height: 100px;
      position: fixed;
      bottom: 0;
      max-width: 260px;
      padding: 9px 15px;
      background: #242f3e;
      .image {
        float: left;
        display: inline-block;
        width: 20%;
        img {
          width: 100%;
          height: auto;
          border-radius: 100%;
        }
      }
      .positon {
        float: left;
        display: inline-block;
        width: 80%;

        .wrapper {
          text-align: left;
          padding: 5px 20px;
          p {
            color: $white;
          }
          .title {
            font-family: "robotothin";
          }
        }
      }
    }
  }
}

.sideNavBar__company {
  padding: 26px 26px 0 26px;

  .logo {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .name {
    color: $white;
    display: inline-block;
    padding-left: 8px;
    font-size: 28px;
    position: absolute;
  }
}

.sideNavBar__profile {
  .logo {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .name {
    color: $white;
    display: inline-block;
    padding-left: 8px;
    font-size: 18px;
    position: absolute;
  }

  .title {
    display: inline-block;
    padding-left: 8px;
    position: absolute;
    padding-top: 24px;
  }
}

.sideNavBar__sidebar-header {
  text-align: left;
  background: rgb(35, 47, 62);
  min-height: 64px;

  .heading {
    color: $white;
    font-size: 26px;
    padding: 16px 0 0 26px;
  }
}
