@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.InventoryCostReport__loader {
  padding: 20% 40%;
}

.InventoryCostReport__wrapper {
  .report-action-btn {
    margin-right: 6px;
  }

  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .InventoryCostReport__item-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }

  .InventoryCostReport__total-card {
    text-align: center;
    color: $white;
    background-color: $dark-blue;
  }

  .InventoryCostReport__itemCost {
    text-align: right !important;
  }
}
