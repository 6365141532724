@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";
@import "../../../../assets/css/mixins";
@import "../../../../assets/css/animations";

// Colors
$toggleColors: $dark-blue $blue $bright-blue $red $orange $black $green;
$colorNames: deep-blue blue bright-blue red orange black green;

// Variables
$defaultColor: $bright-blue;
$toggleDisabledOpacity: 0.5;
$toggleSize: 20px;
$toggleSize-sm: 15px;
$toggleSize-xs: 10px;

$animationMs: 100ms;

/* TODO: fixa så man inte får focus style when disabled */

@mixin up-ios-toggle-color($color, $className) {
  &.Toggle--checked.Toggle--#{$className}:not(.no-border) {
    border-color: $color;
    @include box-shadow(0 0 0 1px $color);
  }

  &.Toggle--#{$className}:before {
    background-color: $color;
  }
}

@mixin up-ios-toggle-next-color($color, $className) {
  &:checked + .Toggle.Toggle--#{$className}:not(.no-border) {
    border-color: $color;
    @include box-shadow(0 0 0 1px $color);
  }
}

@mixin up-ios-toggle-size($size, $postfix) {
  &.Toggle--#{$postfix} {
    font-size: $size;
    height: $size;

    &.Toggle--checked .Toggle__handle {
      margin-left: -$size;
    }

    .Toggle__handle {
      height: $size;
      width: $size;
      @include border-radius($size);
    }
  }
}

@mixin up-ios-toggle-handle-size($size, $postfix) {
  &.Toggle--#{$postfix} {
    .Toggle__handle {
      margin-left: -$size;
    }
  }
}

// TODO: make classes like .toggle-unchecked-blue

// Variables
$iosToggleCheckedBorder: $blue;
$iosToggleDisabledOpacity: 0.5;
$iosToggleActiveHandleWidth: 1.2em;
$iosToggleActiveCheckedLeft: 1.5em;

$iosToggle-width-constant: 1.7em;
$iosAnimationMs: 200ms;

$iosSize: 18px;
$iosSize-sm: 14px;
$iosSize-xs: 10px;
$iosSize-xxs: 10px;
$iosSize-lg: 22px;
$iosSize-xl: 30px;

/* The class that hides the original checkboxes*/
.Toggle__hidden-checkbox {
  display: none !important;

  // Checked
  &:checked + .Toggle {
    border-color: $iosToggleCheckedBorder;

    // Fill
    &:before {
      @include opacity(1);
      @include transform(scale(1));
    }

    &:active .Toggle__handle {
      left: $iosToggleActiveCheckedLeft;
    }

    @include up-ios-toggle-handle-size($iosSize-sm, sm);
    @include up-ios-toggle-handle-size($iosSize-xs, xs);
    @include up-ios-toggle-handle-size($iosSize-xxs, xxs);
    @include up-ios-toggle-handle-size($iosSize-lg, lg);
    @include up-ios-toggle-handle-size($iosSize-xl, xl);

    .Toggle__handle {
      left: $iosToggle-width-constant;
      margin-left: -$iosSize;
    }
  }

  // Disabled
  &[disabled] + .Toggle {
    @include opacity($iosToggleDisabledOpacity);
  }

  // Active
  &:active {
    .Toggle__handle {
      width: $iosToggleActiveHandleWidth;
    }
  }

  // Styles
  @each $currentColor in $toggleColors {
    $i: index($toggleColors, $currentColor);
    @include up-ios-toggle-next-color($currentColor, nth($colorNames, $i));
  }
}

.Toggle {
  display: inline-block;
  position: relative;
  font-size: $iosSize;
  height: $iosSize;
  width: $iosToggle-width-constant;
  display: inline-block;
  cursor: pointer;
  background-color: $grey-4;
  vertical-align: middle;
  @include box-shadow(0 0 0 1px $grey-6);
  @include border-radius($up-btn-height);
  @include opacity(1);
  @include transition(border-color ease-in $iosAnimationMs);
  @include transition(opacity ease-in $iosAnimationMs);
  @include disable-user-select();

  /* fill */
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: $iosToggleCheckedBorder;
    border-radius: $up-btn-height;
    top: 0;
    left: 0;
    @include transition(all ease-in $iosAnimationMs);
    @include opacity(0);
    @include transform(scale(0));
  }

  /* focus */
  &.focus,
  &:focus {
    outline: none;
  }

  &:hover .Toggle__handle {
    box-shadow: 0 0.1em 6px rgba($black, 0.3);
  }

  @include up-ios-toggle-size($iosSize-xl, xl);
  @include up-ios-toggle-size($iosSize-lg, lg);
  @include up-ios-toggle-size($iosSize-sm, sm);
  @include up-ios-toggle-size($iosSize-xs, xs);
  @include up-ios-toggle-size($iosSize-xxs, xxs);

  &.transparent {
    background-color: rgba($white, 0.2);
  }

  &.no-border {
    border: none;
    @include box-shadow(none);
  }

  /* checked */
  &.Toggle--checked {
    border-color: $iosToggleCheckedBorder;

    /* fill (checked) */
    &:before {
      @include opacity(1);
      @include transform(scale(1));
    }

    /* handle (checked) */
    .Toggle__handle {
      left: $iosToggle-width-constant;
      margin-left: -$iosSize;
    }

    /* handle (active, checked) */
    &.active,
    &:active {
      .Toggle__handle {
        left: $iosToggleActiveCheckedLeft;
      }
    }
  }

  // active
  &.active,
  &:active {
    // Handle
    .Toggle__handle {
      width: $iosToggleActiveHandleWidth;
    }
  }

  /* disabled */
  &.Toggle--disabled {
    @include opacity($iosToggleDisabledOpacity);
  }

  /* handle */
  .Toggle__handle {
    position: absolute;
    left: 0;
    top: 0;
    width: $iosSize;
    height: $iosSize;
    display: inline-block;
    background-color: $grey-1;
    margin-left: 0;
    @include border-radius($iosSize);
    @include box-shadow(0 0.024em 0 rgba($black, 0.2));
    @include transition(all ease-in $iosAnimationMs);
  }

  // Styles
  @each $currentColor in $toggleColors {
    $i: index($toggleColors, $currentColor);
    @include up-ios-toggle-color($currentColor, nth($colorNames, $i));
  }
}
