.AddAgentModal__modal {
  .resume-upload {
    .Input__input {
      padding: 0;
      line-height: 27px;
    }
  }

  .AddAgentModal__agreement-file {
    overflow: hidden;
    a {
      color: #007bff;
    }
  }

  .AddAgentModal__delete-agreement {
    margin-left: 5px;
  }
}
