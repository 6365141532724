.login {
  background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 7, 71, 0.85)),
    url("../img/backimage1.jpg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #008277;
  .login-form-card {
    margin: 20% 0 0 25%;
    width: 50%;
  }
  .login-form-header {
    color: $white;
    padding-bottom: 10px;
  }
  .login-form {
    margin-top: 20%;
    .wrapper {
      width: 70%;
      margin: 0 auto;
      .title {
        padding-bottom: 40px;
        text-align: center;
        p {
          color: $white;
          font-family: "robotomedium";
        }
        img {
          width: 43%;
        }
        h2 {
          font-family: "robotobold";
          color: $white;
          text-transform: uppercase;
          padding: 15px 0 10px 0;
          letter-spacing: 1px;
          color: $textColorSecondary;
        }
      }
      form {
        margin: 0 auto;
        .form-group {
          margin-bottom: 25px;
          position: relative;
          .form-control {
            height: 50px;
            padding: 10px 45px;
            background-color: $white;
            opacity: 1;
          }
          .svg-inline--fa {
            position: absolute;
            top: 34%;
            left: 4%;
            font-size: 17px;
            color: $iconColor;
          }
        }
        .remember {
          text-align: left;
          color: $white;
          padding: 0 0 0 0;
          label {
            span {
              padding-left: 12px;
              font-size: 12px;
              text-transform: capitalize;
              font-family: "robotomedium";
            }
          }
          .fogotpassword {
            float: right;
            a {
              font-family: "robotomedium";
              font-size: 12px;
              // text-decoration: underline;
              text-transform: capitalize;
            }
          }
        }
      }
      .button {
        padding-top: 24px;
        .normal-btn {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }
  }
}
