.mentorship__feedbacks {
  .tableWrapper {
    .feedback-col {
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .feedback-actions {
      text-align: right;
    }
  }

  .headerTitle-wrap {
    display: inline-flex;
  }
}

.mentorship__loader {
  padding: 20% 40%;
}
