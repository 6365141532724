@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";
@import "../../../../assets/css/mixins";
@import "../../../../assets/css/animations";

.Loader {
  width: 100px;
  height: 100px;
  vertical-align: middle;
  line-height: 1;

  svg {
    width: 100%;
    height: 100%;
    -webkit-animation: intro ease-out 0.4s;
    animation: intro ease-out 0.4s;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  @include keyframes(intro) {
    70% {
      -webkit-transform: scale(1.1, 1.1);
    }
    100% {
      -webkit-transform: scale(1, 1);
    }
  }

  /* LIGHT THEME */
  g {
    .upsales-u {
      fill: $blue;
    }

    .progress-circle {
      stroke: $blue;
    }
  }

  /* DARK THEME */
  // FIXME: Gör om till riktiga classer type loader-white
  &.loader-white,
  &.dark {
    g {
      .upsales-u {
        fill: $white;
      }

      .progress-circle {
        stroke: $white;
      }
    }
  }

  &.loader-bright-blue {
    g {
      .upsales-u {
        fill: $bright-blue;
      }

      .progress-circle {
        stroke: $bright-blue;
      }
    }
  }

  &.small {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;

    .progress-circle {
      stroke-width: 3px;
    }
  }
}

.Loader--xs {
  width: 25px;
  height: 25px;

  .progress-circle {
    stroke-width: 3px;
  }
}

.Loader--sm {
  width: 50px;
  height: 50px;

  .progress-circle {
    stroke-width: 3px;
  }
}

.Loader--md {
  width: 100px;
  height: 100px;

  .progress-circle {
    stroke-width: 3px;
  }
}

.Loader--lg {
  width: 150px;
  height: 150px;

  .progress-circle {
    stroke-width: 3px;
  }
}
