@import './color';

.modal{
    .infoModal__infoModal{
        .modal-content{
            padding: 10px 20px;
            border: none;
            background-color:$grey;
            .modal-header{
                text-align: center;
                border: none;
                .modal-title{
                    text-align: center;
                    margin: 0 auto;
                    font-size: 20px;
                    text-transform: uppercase;
                    font-family: 'robotobold';
    
                }
                .close{
                    position: absolute;
                    top: 27px;
                    right: 28px;
                }
            }
            .modal-body{
                
                .details{
                    padding-bottom: 40px;
                    h4{
                        margin: 0;
                        padding: 0;
                        padding: 10px 0px;
                        font-size: 14px;
                        span{
                            padding-right: 15px;
                        }
                    }
                }
                .tableLeave{
                    
                    table{
                        
                        thead{
                            background-color: $sidebarColor;
                            color: $white;
                            text-align: center;
                            border: none;
                            tr{
                                border: none;
                                th{
                                    border: none;
                                }
                            }
                        }
                        tbody{
                            text-align: center;
                            border: none;
                            tr{
                                border: none;
                                td{
                                    border: none;
                                }
                            }
                        }
                    }
                }
    
            }
            .modal-footer{
                text-align: center;
                padding: 40px 0px;
                border: none;
                border-bottom-left-radius: 0;

                .normal-btn{
                    width: 20%;
                    margin: 0 auto;
                    
                }
            }
        }
    }
}

.modal-backdrop.show{
    opacity: 0.8;
    -webkit-box-shadow: -3px -5px 27px -12px rgba(0, 0, 0, 0.51);
    -moz-box-shadow: -3px -5px 27px -12px rgba(0, 0, 0, 0.51);
    box-shadow: -3px -5px 27px -12px rgba(0, 0, 0, 0.51);
}