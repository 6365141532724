.HrmSurveyAddUsers__wrapper {
  margin-top: 35px;
  .Button__loader-wrap {
    padding-left: 26px;
  }
}

.HrmSurveyAddUsers__header {
  text-align: center !important;
}

.HrmSurveyAddUsers__question-card {
  border-right: 1px solid grey;
}

.HrmSurveyAddUsers__action-btns {
  text-align: center !important;
}

.HrmSurveyAddUsers__supervisor-label {
  margin-left: 20px;
}

.HrmSurveyAddUsers__submit-info {
  text-align: center;
}
