.InventoryMyItems__wrapper {
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .InventoryMyItems__loader {
    padding: 20% 40%;
  }

  .Inventory__my-list-item-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }
}
