@import "../../../../assets/css/color";
@import "../../../../assets/css/variable";

$paddingLgV: 13px;
$paddingMdV: 10px;
$paddingSmV: 7px;
$heightLg: $tabsLgHeight - ($paddingLgV * 2);
$heightMd: $tabsMdHeight - ($paddingMdV * 2);
$heightSm: $tabsSmHeight - ($paddingSmV * 2);
$hoverOpacity: 0.3;
$hoverBorderSize: 2px;

.Tab {
  flex-grow: 1;
  font-size: 14px;
  transition: color ease 200ms;
  transition: background-color ease 200ms;
  cursor: pointer;
  box-sizing: content-box;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
}

/* Children */
.Tab__subtitle {
  font-weight: normal;
}

/* STATES */
.Tab--lg.Tab--only-icon {
  width: $heightLg;
}
.Tab--md.Tab--only-icon {
  width: $heightMd;
}
.Tab--sm.Tab--only-icon {
  width: $heightSm;
}
.Tab--no-flex {
  flex-grow: 0;
}

.Tab--has-icon:not(.Tab--only-icon):not(.Tab--align-center),
.Tab--has-icon:not(.Tab--only-icon).Tab--has-subtitle {
  padding-left: 40px;

  .Icon {
    position: absolute;
    top: 0;
    left: 15px;
    line-height: 50px;
  }
}

.Tab--has-icon.Tab--align-center:not(.Tab--only-icon) .Icon {
  margin-right: 5px;
}

.Tab--align-left {
  text-align: left;
}

.Tab--align-center {
  text-align: center;
}

.Tab--align-right {
  text-align: right;
}

.Tab--selected {
  font-weight: bold;
}

.Tab--disabled {
  cursor: not-allowed;
}

/* SIZES */
.Tab--lg {
  height: $heightLg;
  line-height: $heightLg;
  padding: $paddingLgV 15px;
}
.Tab--lg.Tab--hover-border:hover {
  border-bottom: $hoverBorderSize solid;
  padding-bottom: $paddingLgV - $hoverBorderSize;
}
.Tab--lg.Tab--has-subtitle {
  line-height: 17px;
}
.Tab--lg .Tab__subtitle {
  font-size: 12px;
}

.Tab--md {
  height: $heightMd;
  line-height: $heightMd;
  padding: $paddingMdV 15px;
}
.Tab--md.Tab--hover-border:hover {
  border-bottom: $hoverBorderSize solid;
  padding-bottom: $paddingMdV - $hoverBorderSize;
}
.Tab--md.Tab--has-subtitle {
  line-height: 17px;
}
.Tab--md .Tab__subtitle {
  font-size: 12px;
}

.Tab--sm {
  height: $heightSm;
  line-height: $heightSm;
  padding: $paddingSmV 11px;
}
.Tab--sm.Tab--hover-border:hover {
  border-bottom: $hoverBorderSize solid;
  padding-bottom: $paddingSmV - $hoverBorderSize;
}
.Tab--sm.Tab--has-subtitle {
  line-height: 14px;
}
.Tab--sm .Tab__subtitle {
  font-size: 10px;
}

/* COLORS */
.Tab--white {
  color: $grey-10;
  background-color: $white;
}
.Tab--white.Tab--selected {
  color: $blue;
}
.Tab--white .Tab__subtitle {
  color: $grey-10;
}
.Tab--white.Tab--selected .Tab__subtitle {
  color: $bright-blue;
}
.Tab--white:hover {
  color: $grey-13;
}
.Tab--white.Tab--hover-border:hover {
  border-bottom-color: rgba($blue, $hoverOpacity);
}
.Tab--white.Tab--disabled:hover {
  color: $grey-10;
}
.Tab--white .ColorSwitcher {
  color: $white;
  background-color: $grey-10;
}
.Tab--white.Tab--selected .ColorSwitcher {
  background-color: $blue;
}
.Tab--white:hover .ColorSwitcher {
  background-color: $grey-13;
}
.Tab--white.Tab--disabled:hover .ColorSwitcher {
  color: $white;
  background-color: $grey-10;
}

.Tab--blue {
  color: rgba($white, 0.7);
  background-color: $blue;
}
.Tab--blue.Tab--selected {
  color: $white;
}
.Tab--blue .Tab__subtitle {
  color: rgba($white, 0.7);
}
.Tab--blue.Tab--selected .Tab__subtitle {
  color: rgba($white, 0.9);
}
.Tab--blue:hover {
  color: $white;
}
.Tab--blue.Tab--hover-border:hover {
  border-bottom-color: rgba($white, $hoverOpacity);
}
.Tab--blue.Tab--disabled:hover {
  color: rgba($white, 0.7);
}
.Tab--blue .ColorSwitcher {
  color: $blue;
  background-color: rgba($white, 0.7);
}
.Tab--blue.Tab--selected .ColorSwitcher {
  background-color: $white;
}
.Tab--blue:hover .ColorSwitcher {
  background-color: $white;
}
.Tab--blue.Tab--disabled:hover .ColorSwitcher {
  color: $blue;
  background-color: rgba($white, 0.7);
}

.Tab--bright-blue {
  color: rgba($white, 0.7);
  background-color: $bright-blue;
}
.Tab--bright-blue.Tab--selected {
  color: $white;
}
.Tab--bright-blue .Tab__subtitle {
  color: rgba($white, 0.7);
}
.Tab--bright-blue.Tab--selected .Tab__subtitle {
  color: rgba($white, 0.9);
}
.Tab--bright-blue:hover {
  color: $white;
}
.Tab--bright-blue.Tab--hover-border:hover {
  border-bottom-color: rgba($white, $hoverOpacity);
}
.Tab--bright-blue.Tab--disabled:hover {
  color: rgba($white, 0.7);
}
.Tab--bright-blue .ColorSwitcher {
  color: $bright-blue;
  background-color: rgba($white, 0.7);
}
.Tab--bright-blue.Tab--selected .ColorSwitcher {
  background-color: $white;
}
.Tab--bright-blue:hover .ColorSwitcher {
  background-color: $white;
}
.Tab--bright-blue.Tab--disabled:hover .ColorSwitcher {
  color: $bright-blue;
  background-color: rgba($white, 0.7);
}

.Tab--medium-purple {
  color: rgba($white, 0.7);
  background-color: $medium-purple;
}
.Tab--medium-purple.Tab--selected {
  color: $white;
}
.Tab--medium-purple .Tab__subtitle {
  color: rgba($white, 0.7);
}
.Tab--medium-purple.Tab--selected .Tab__subtitle {
  color: rgba($white, 0.9);
}
.Tab--medium-purple:hover {
  color: $white;
}
.Tab--medium-purple.Tab--hover-border:hover {
  border-bottom-color: rgba($white, $hoverOpacity);
}
.Tab--medium-purple.Tab--disabled:hover {
  color: rgba($white, 0.7);
}
.Tab--medium-purple .ColorSwitcher {
  color: $medium-purple;
  background-color: rgba($white, 0.7);
}
.Tab--medium-purple.Tab--selected .ColorSwitcher {
  background-color: $white;
}
.Tab--medium-purple:hover .ColorSwitcher {
  background-color: $white;
}
.Tab--medium-purple.Tab--disabled:hover .ColorSwitcher {
  color: $medium-purple;
  background-color: rgba($white, 0.7);
}
