.InventoryQuantityReport__loader {
  padding: 20% 40%;
}

.InventoryQuantityReport__wrapper {
  .report-action-btn {
    margin-right: 6px;
  }

  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }

  .InventoryQuantityReport__item-actions {
    text-align: right;

    .Button {
      margin-right: 2px;
    }
  }
}
