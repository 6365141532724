@import "../../../../../assets/css/color";
@import "../../../../../assets/css/variable";

.HrmSurveyEmployee__wrapper {
  .HrmSurveyEmployee__loader {
    padding: 20% 40%;
  }
  .tableWrapper .table thead tr th {
    padding-left: 10px;
  }
  .HrmSurveyEmployee__detailBox {
    border-right: 1px solid $grey-8;
  }

  .HrmSurveyEmployee__detailBoxFormGroup {
    text-align: center;
  }
}

.HrmSurveyEmployee__feedback-question {
  margin-bottom: 10px;
}
