.Feedback__invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.Feedback__valid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: green;
}

.Feedback__info {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: grey;
}
