@import "../../../../../../assets/css/color";
@import "../../../../../../assets/css/variable";

.AddFeedbackModal__modal {
  .ck-editor__editable {
    min-height: 200px;
  }

  .RadioListItem {
    label {
      padding-left: 5px;
      color: $grey-10;
    }
  }
}
