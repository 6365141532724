.hrmRecruitmentDashboard__wrapper {
  .hrmRecruitmentDashboard__loader {
    padding: 20% 40%;
  }

  .hrmRecruitmentDashboard__info-card {
    width: 100%;
    padding: 15px;
    border-radius: 8px;
    display: inline-block;
    color: white;
    text-align: center;
  }

  .hrmRecruitmentDashboard__info-column {
    padding: 10px;
    background-color: white;
  }

  .hrmRecruitmentDashboard__application-source {
    text-align: center;
  }

  .hrmRecruitmentDashboard__details-card-root {
    padding: 15px;
    border-radius: 8px;
    display: inline-block;
    color: white;
    background-color: #052a4f;
  }

  .hrmRecruitmentDashboard__details-card-levelOne {
    min-width: 100px;
    padding: 10px;
    border-radius: 8px;
    display: inline-block;
    color: white;
    background-color: #002f65;
  }

  .hrmRecruitmentDashboard__info-card {
    border-radius: 8px;
    display: inline-block;
    color: white;
    background-color: #3367c0;
  }
}
