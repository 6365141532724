@import "../color";

.NewRecordModal__modal {
  .modal-header {
    background-color: $modalHeaderPrimary;
  }

  .form-row {
    padding-bottom: 14px;
  }

  .record-time-hours {
    padding-right: 0px;
  }

  .record-time-minutes {
    padding-left: 0px;
  }
}
