@import "../../../../../../assets/css/color";
@import "../../../../../../assets/css/variable";

.feedback__candidate {
  .feedback__review-card {
    margin-top: 26px;
  }

  .feedback__startRating {
    text-align: right;
  }

  .feedback__comments {
    margin-top: 26px;
    .Feedback__info {
      padding-bottom: 6px;
    }
  }

  .feedback__top-card-group {
    text-align: center;
  }

  .feedback__top-card-col {
    border-right: 1px solid $grey-8;
  }

  .feedback__decision-btn {
    min-width: 75px;
  }
}
