@import "./color";

.form-group {
  text-align: left;
  padding: 10px 0;
  .isFocused {
    // position: absolute;
    // left: 18px;
    // top: 32px;
    // transform: translate(0px, 0px) scale(1);
    // background-color: $white;
    // padding: 2px;
    // font-size: 14px;
  }
  // .isFocusedInactive{
  //   position: absolute;
  //   left: 0px;
  //   top: -20px;
  //   z-index: 1;
  //   transform: translate(14px, 27px) scale(1);
  //   pointer-events: none;
  //   transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  //   background-color: $white;
  //   padding: 2px;
  //   font-size: 14px;
  // }
}
// .form-group {
//   text-align: left;
//   position: relative;
//   label {
//     font-family: "robotoregular";
//     font-size: 12px;
//     text-transform: capitalize;
//     padding-bottom: 5px;
//     color: $sidebarColor;
//     font-size: 14px;
//     color: "black";
//     opacity: 1;
//   }
// .form-control {
// border: none;
// border: 1px solid $sidebarColor;
// height: 50px;
// border-radius: 4px;
// padding: 18px 15px;
// font-size: 14px;
// font-family: "robotomedium";
// opacity: .6;

//   &:focus {
//     box-shadow: none;
//   }
// }
// .svg-inline--fa {
//   position: absolute;
//   top: 61%;
//   left: 3%;
//   z-index: 99;
//   color: $sidebarColor;
//   font-size: 14px;
//   opacity: 0.3;
// }
// .input {
//   padding: 10px 15px 10px 40px;
// }
// }
.input {
  padding: 10px 15px 10px 40px;
}
select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tableWrapper {
  padding: 20px;
  background-color: white;

  .table {
    padding-top: 20px;
    padding-bottom: 20px;
    thead {
      background-color: $white;
      color: $white;
      text-align: center;
      border: none;
      tr {
        border: none;
        border-bottom: 1px solid #eee;
        th {
          border: none;
          padding: 15px 0;
          font-family: "robotoregular";
          font-size: 14px;
          color: black;
          text-align: left;
          padding-left: 20px;
        }
      }
    }
    tbody {
      text-align: center;
      border: none;
      tr {
        border: none;
        text-align: left;
        border-bottom: 1px solid #eee;
        background: $white;

        td {
          border: none;
          font-family: "robotoregular";
          font-size: 14px;
          color: black;
          // text-align: left;
          // padding: 20px;
        }
        th {
          border-top: none;
          padding: 20px 0;
          padding-left: 20px;
        }
      }
    }
  }
}

.hr {
  margin-top: 24px;
  margin-bottom: 24px;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
}

.header-table-space {
  padding-bottom: 10px;
}

.list-header-title {
  text-transform: uppercase;
  font-family: "robotobold";
  font-size: 22px;
}

.list-sub-header-title {
  font-family: "robotobold";
  font-size: 18px;
  color: $grey-11;
}

// .btn-primary {
//   color: $white;
//   background-color: $primary;
//   border: none;
//   &:hover {
//     background-color: $buttonColorOneHover;
//     transition: 0.3s;
//   }
//   &:focus {
//     box-shadow: none;
//     border-color: none;
//     background-color: $buttonColorOne;
//     border-color: $buttonColorOne;
//   }
// }

.paddingTop_20 {
  padding-top: 20px;
}
.paddingTop_40 {
  padding-top: 40px;
}
.paddingTop_60 {
  padding-top: 60px;
}
.paddingBottom_20 {
  padding-bottom: 20px;
}

.nav-tabs {
  border-bottom: none;
  .nav-item {
    padding: 10px 30px 10px 0;
    a {
      border: none;
      border-top-left-radius: 0;
      padding: 0 0 5px 0;
      // padding: 10px;
    }
    .active {
      border: none;
      border-bottom: 2px solid $sidebarColor;
      padding: 0 0 5px 0;
      border-top-left-radius: 0;
    }
  }
}

.form-title {
  padding-bottom: 20px;
  h5 {
  }
}
