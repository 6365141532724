@import "../../../../../../assets/css/color";
@import "../../../../../../assets/css/variable";

.feedbackCard__feedback {
  padding-top: 20px;
}

.feedbackCard__review-card {
  margin-top: 20px;
}

.feedbackCard__startRating {
  text-align: right;
  .badge {
    margin-right: 6px;
  }
}

.feedbackCard__interviewStage {
  display: inline-flex;
  padding-right: 8px;
  color: $grey-10;
}
